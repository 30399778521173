<template>
  <section id="profile">
     <v-row>
      <v-col cols="12" xl="8" lg="10">
        <div class="text-h5 ml-2 mb-5">Manage Profile</div>

        <v-sheet class="pa-5 custom-card">
          <div class="d-flex align-center">
            <div class="">
              <v-avatar
                color="secondary"
                tile
                size="130"
                v-if="user.image == null"
              >
                <v-btn icon @click="$refs.file.click()">
                  <v-icon size="18">mdi-image</v-icon>
                </v-btn>
              </v-avatar>
              <v-avatar v-else size="130" tile>
                <v-img
                  :src="`${user.aws}/${user.model}/${user.uuid}/image/${user.image.image}`"
                >
                  <v-btn
                    absolute
                    right
                    bottom
                    icon
                    @click="$refs.file.click()"
                    style="bottom: 0%; right: 0%"
                  >
                    <v-icon size="18">mdi-image</v-icon>
                  </v-btn>
                </v-img>
              </v-avatar>
            </div>
            <div class="ml-5">
              <div class="display-1 font-weight-bold">
                {{ user.firstname + " " + user.lastname }}
              </div>
              <div class="caption">{{ user.email }}</div>
              <div class="caption">{{ user.contact }}</div>
            </div>
          </div>
        </v-sheet>
        
        <v-row class="my-5">
          <v-col cols="12" lg="6">
            <v-form @submit.prevent="personal" @ref="updateForm">
              <v-sheet class="pa-5">
                <div class="caption mb-10 font-weight-bold">PERSONAL INFORMATION</div>
                <v-text-field label="Firstname" v-model="user.firstname" />
                <v-text-field label="Lastname" v-model="user.lastname" />
                <v-text-field label="Middlename" v-model="user.middlename" />
                <v-text-field label="Contact Number" v-model="user.contact" />
                <div class="d-flex justify-end">
                  <v-btn type="submit" color="primary">Save Changes</v-btn>
                </div>
              </v-sheet>
            </v-form>
          </v-col>
          <v-col cols="12" lg="6">
            <v-sheet class="pa-5">
              <div class="caption mb-10 font-weight-bold">PROFILE INFORMATION</div>
              <v-form @submit.prevent="profile" ref="pass">
                <v-text-field  
                label="Current Password"
                :append-icon="pass0?'mdi-eye-off-outline': 'mdi-eye-outline'"
                @click:append="pass0=!pass0"
                :error-messages="errors.current_password"
                v-model="form.current_password"
                :type="pass0?'text':'password'"/>
                <v-text-field  
                label="Password"
                :append-icon="pass1?'mdi-eye-off-outline': 'mdi-eye-outline'"
                @click:append="pass1=!pass1"
                :error-messages="errors.password"
                v-model="form.password"
                :type="pass1?'text':'password'"/>
                <v-text-field 
                label="Confirm Password"
                :append-icon="pass2?'mdi-eye-off-outline': 'mdi-eye-outline'"
                @click:append="pass2=!pass2"
                v-model="form.password_confirmation"
                :type="pass2?'text':'password'"/>
                <div class="d-flex justify-end">
                  <v-btn type="submit" color="primary">Save Changes</v-btn>
                </div>
              </v-form>
              
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <input type="file" @change="image" ref="file" class="d-none" />

    <snackbar
    :snack="dialog"
    :text="text"
    timeout="2000"
    @close="dialog=false"/>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data: () => ({
    form: { current_password: '',password: '', password_confirmation: ''},
    pass: false,
    pass1: false,
    pass2: false,
    loading: false,
    loading2: false,
    dialog: false,
    text: ''
  }),
  computed: {
    ...mapState('teacher', {
      user: (state) => state.user,
      errors: (state) => state.errors
    })
  },
  methods: {
    ...mapActions('teacher', [
      'accountImage',
      'authAction'
    ]),

    profile() {
      this.loading = true
      this.$store.dispatch('teacher/updatePasswordAction', this.form)
      .then(() => {
        this.loading= false
        if(this.$errors('teacher')) {
          console.log('error uccured')
          return
        }
        this.$refs.pass.reset()
        this.dialog=true
        this.text= 'Password Updated'
      })
    },
    personal() {
      this.loading2 = true
      this.$store.dispatch('teacher/updateInfoAction', this.user)
      .then(() => {
        this.loading2= false
        if(this.$errors('teacher')) {
          console.log('error uccured')
          return
        }
        this.dialog=true
        this.text= 'Information Updated'
      })
    },
    image(e) {
      let data = new FormData();
      data.append("image", e.target.files[0]);
      this.accountImage(data).then(() => {
        this.authAction();
      });
    },
  }
}
</script>